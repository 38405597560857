import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Component } from 'react';
import RecorderJS from 'recorder-js';
import '../index-style.css';

import Modal from 'react-bootstrap/Modal';

import { getAudioStream, exportBuffer } from '../utils/audio';

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      recording: false,
      recorder: null,
      audioFile: null,
      requestMicAccess: false,
      recordSampleRate: null,
      audioContext: null
    };
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    let stream;
    try {
      stream = await getAudioStream();
    } catch (error) {
      // Users browser doesn't support audio.
      // Add your handler here.
      console.log(error);
      console.log('Please enable microphone access / your browser does not support audio')
    }

    const audioContext = new (window.AudioContext || window.webkitAudioContext)()//({sampleRate:16000});    
    this.setState({ 
      stream,
      audioContext: audioContext,
      });
  }

  startRecord() {
    const stream = this.state.stream;   
    const audioContext = this.state.audioContext;

    const recordSampleRate = audioContext.sampleRate;
    const recorder = new RecorderJS(audioContext);
    recorder.init(stream);

    this.setState(
      {
        recorder,
        recording: true,
        recordSampleRate: recordSampleRate,
      },
      () => {
        recorder.start();
      }
    );

    setTimeout(this.stopRecord, 1800)
  }

  async stopRecord() {
    const { recorder } = this.state;

    const { buffer } = await recorder.stop()
    // const audioSamples = buffer[0]
    // const audio = exportBuffer(buffer[0], this.state.recordSampleRate); // try buffer[0]

    const exportBufferOutput = exportBuffer(buffer[0], this.state.recordSampleRate);
    const audioSamples = exportBufferOutput[0];
    const audio = exportBufferOutput[1]

    // Process the audio here.

    this.setState({
      recording: false
    });
    this.props.onSubmit(audioSamples, audio);

    //var url = URL.createObjectURL(audio);
    //this.setState({audioFile: url})
  }

  handleClick(){
    if (!this.state.stream){
      this.setState({
        requestMicAccess: true,
      })
    }
    else{
      this.state.recording ? this.stopRecord() : this.startRecord()
    }

  }

  render() {
    const { recording } = this.state;


    return (
      <div>
        <Modal show={this.state.requestMicAccess}>
          <Modal.Header>
            <Modal.Title>
             Please allow microphone access to use this website <i className="fa fa-microphone"/>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-justify'>We do not store any of your audio data and all
            audio processing is done in your browser. You may need to refresh the page for 
            microphone settings to be updated. </Modal.Body>
        </Modal>
        <button className='mx-auto btn btn-danger shadow' disabled={recording}
          onClick={this.handleClick}
          >
          {recording ? 'Recording...' : 'Record'}
        </button>
      </div>
    );
  }
}

export default Recorder;