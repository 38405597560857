import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Navbar, Nav } from 'react-bootstrap';

import Landing from './Components/Landing';
import HSK1 from './Components/HSK1';
import Game from './Components/Game';
import SignUp from './Components/SignUp';
import FAQs from './Components/FAQs';
import './index-style.css';

import ReactGA from 'react-ga';

var host = window.location.hostname;
if(host !== "localhost")
{
	ReactGA.initialize('UA-150372160-01');
	ReactGA.pageview('/homepage');
}


class Header extends Component {
  render(){
      return(
        <div className='container'>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
          <Navbar.Brand href="/">
              <img src="/4_tones_square.PNG" width="35" height="30" 
              className="d-inline-block align-top" alt=""/>
              <strong className = 'pl-2 d-none d-sm-inline-block mute-link'>
               Mandarin Tone Trainer
              </strong>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto mute-link">
              <Nav.Link href="/faq">FAQs</Nav.Link>
            </Nav>
            <Nav>
            <a className="btn btn-outline-success my-2 my-sm-0" 
                  href="/signup">Sign Up</a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>


          <nav className="navbar navbar-light bg-white">
            <a className="navbar-brand" href="/">
            </a>

          </nav>  
        </div>    
      )
  }
}


class App extends Component {
  render(){
    return(
      <Router>
        <Header/>
        <Switch>
          <Route path = '/games/:gameType?/:word?' component = {Game} />
          <Route path = '/hsk1' component = {HSK1} />
          <Route path = '/signup' component = {SignUp} />
          <Route path = '/faq' component = {FAQs} />
          <Route path = '/' component = {Landing} />          
          <Redirect to='/games'/>
        </Switch>
      </Router>
      )
  }
}



export default App;

