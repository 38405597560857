import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import '../index-style.css';


class SignUp extends Component{
  render(){
    return(
      <div className = 'App container'>
        <div className = "row justify-content-around">
          <div className = "col-md-8 col-12 py-2">
            <img className = "img-fluid" src="/mandarin_tone_trainer_cover.PNG" alt="coming_soon_image" />
          </div>
        </div>
        <p className = "pt-5 pb-3">We plan to release a fully-fledged app soon. Subscribe for more updates below.</p>         
        <div class="embed-responsive embed-responsive-1by1">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe1nYH2czLDkg67EwIjsit5rO41XdRx2r-f4TV9u3fLGjpGXw/viewform?embedded=true" 
          width="640" height="383"
          class="embed-responsive-item"
          title="google form signup"
          marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </div>
      )
  }
}

export default SignUp;