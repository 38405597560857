import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-150372160-01');


class FacebookBrowserPrompt extends Component{
	render(){
      ReactGA.event({
        category: 'FBbrowser',
        action: 'Show FB browser prompt',
      }); 

		return(
			<div>
	          <div className = "py-2 px-2">
	            <p className="text-danger">
	              Hi, it looks like you are accessing this website from Facebook's 
	              in-app browser, which does not let
	              you record audio. 
	            </p>
	            <p>
	              Please switch to the <strong>Chrome</strong> browser.
	              You can do that by pressing the 
	              ellipsis <i className="fa fa-ellipsis-h"/> in 
	              the top right corner if your phone is Android.
	            </p>
	            <p>
	              You'll also need to allow microphone 
	              access <i className="fa fa-microphone"/> so 
	              that the website can give you feedback on your pronunciation. We do not store any of your audio data and all
	              audio processing is done in your browser.
	            </p>
	            <p>
	              Thanks and sorry for the inconvenience!
	            </p>
	          </div>
	          <div>
	            <p className = "pt-2"><strong>What awaits you in Google Chrome:</strong></p>
	            <div className = "row justify-content-around">
	              <div className = "col-md-3 col-6 py-1">
	                <img className = "img-fluid" src="/honor_mockup.PNG" alt="working_screenshot" />
	              </div>
	            </div>
	          </div>
	        </div>
			)
	}
}

export default FacebookBrowserPrompt;