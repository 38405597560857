import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-150372160-01');



class FAQs extends Component{
  render(){
      ReactGA.event({
        category: 'FAQs',
        action: 'View FAQs',
      }); 

    return(
      <div className='container'>
        <div className = "mx-3 py-5">
          <div className = "mx-3 py-2">
            <h3 className = "text-left"> FAQs </h3>
          </div>
          <ul>
            <li className = 'text-left'>
              <strong>How can I give feedback?</strong>
              <p>We would love to know whether you would/wouldn't want to use a product
               that builds upon this website. You can email us at mandarintonetrainer [at] outlook [dot] com,
              or drop us a message on our 
              <a href="https://www.facebook.com/MandarinToneTrainer/"> Facebook page </a>
               or <a href="https://twitter.com/ToneTrainer/"> Twitter</a>.</p>
            </li>
            <li className = 'text-left'>
              <strong>There is no 'record' button. What's wrong?</strong>
              <p>Please allow microphone access (don't worry, we do not store the audio data).
            If you are not able to allow it, then your browser probably doesn't support audio recording.
            Please try Chrome or Opera browser instead.
              </p>
              <p>
              Please note that the Facebook in-app browser does not support audio recording.
              </p>
            </li>
            <li className = 'text-left'>
              <strong>Nothing happens after I click the recording button. What's wrong?</strong>
              <p>The cause of this is likely to be that your device's microphone is 
              turned off (or isn't connected). 
              Unfortunately the recording feature doesn't yet work on browsers such as Firefox. 
              It should work on Chrome or Opera.
              </p>
            </li>
            <li className = 'text-left'>
              <strong>I don't think the system prediction is right about what tone I said.</strong>
              <p>Unfortunately the system isn't very accurate at the moment, 
              but you will quite likely be able to tell whether you are saying 
              the right tone by looking at your vocal pitch contour and comparing it to that of the teacher.</p>
            </li>
          </ul>
        </div>
      </div>

      )
  }
}

export default FAQs;