import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Plotly from 'plotly.js-cartesian-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

import * as tf from '@tensorflow/tfjs';
import Recorder from './Recorder';
import FacebookBrowserPrompt from './FacebookBrowserPrompt';
import '../index-style.css';
import CardArray from '../CardArray.json'

import Card from 'react-bootstrap/Card';

import ReactGA from 'react-ga';


const Plot = createPlotlyComponent(Plotly);


function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}  


class CardClass {
	constructor(characters, pinyin){
		this.characters = characters
		this.pinyin = pinyin
		this.file_path = 'double_wavs/' + characters + '.wav'
	}
}

class CardDeck {
	constructor(beginCards) {
		this.knownCards = []
		this.unknownCards = []

		// build up unknownCards array
		for (let index in beginCards){
			let element = beginCards[index]
			let newCard = new CardClass(element.char, element.pin)
			this.unknownCards.push(newCard)
		}
	}

	getUnknownCard(){
		return this.unknownCards[Math.floor(Math.random() * this.unknownCards.length)]
	}

	changeCardToKnown(card){
		this.unknownCards.splice(this.unknownCards.indexOf(card), 1)
		this.knownCards.push(card)
	}

	getKnownLength(){
		return this.knownCards.length
	}

	getUnknownLength(){
		return this.unknownCards.length
	}
}


class PlotTemplate extends Component{
	render(){
		return(
			<Plot data={[
                    {
                      z: this.props.dataToPlot,
                      type: 'heatmap',
                      colorscale: 'YIGnBu',
                      showscale: false,
                      transpose: true,
                    },
                  ]} 
                  layout={ {width: 220, height: 120,
                            margin: {l:0, r: 0, b: 0, t: 0},
                            xaxis: {ticks:'', showticklabels: false},
                            yaxis: {ticks: '', showticklabels: false, autorange: 'reversed'},
                            }}
                  config={{staticPlot:true}} 
                /> 
			)
	}

}

class You extends Component{
	/// has state samples, can show cepstrum...
	constructor(props) {
    super(props);
    this.state = {
      attemptSamples: null,
      attemptURL: null,
      yourCepstrum: null,
    }
    this.pressedRecord = this.pressedRecord.bind(this);
  }	

  async pressedRecord(audioSamples, audio){
    var blobURL = URL.createObjectURL(audio)

    this.props.getDataToPlot(audioSamples).then(response => {
        this.setState({ yourCepstrum: response})
      }
    );
    
    this.setState({
      attemptURL: blobURL,
      attemptSamples: audioSamples,
    })

    // to update 'madeAttempt' in HSK1 component
    this.props.recordMadeAttemptFunc();
  }

  	render(){
	  	if (this.props.madeAttempt === true) { 
	      return this.renderAttempt();
	    }
	    else{
	    	return(
	    		<div>
					<Recorder onSubmit={this.pressedRecord}/>
	    		</div>
	    	)
	    }
  	}

	renderAttempt(){
		return(
			<div className="justify-content-center">
				<Recorder onSubmit={this.pressedRecord}/>			
				<Card className="px-2 py-2 my-2 shadow border border-info border-1 my-2" border = "">
				    <Card.Text>
				      <p className="text-left">Your recording</p>
				      	<div className = "row">
				      		<div className = "col-6-sm col-12">
								<audio src={this.state.attemptURL} controls={true}/>
							</div>
				      		<div className = "col-6-sm col-12">
			 					<PlotTemplate dataToPlot={this.state.yourCepstrum}/>				      
			 				</div>
			 			</div>
				    </Card.Text>
				</Card>			
			</div>
		)
	}
}

class Teacher extends Component {
    render(){
    	if (this.props.madeAttempt === false) {
			return(<div></div>)
		}

	  	else if (this.props.teacherCepstrum !== null) { 
	      return this.renderAttempt();
	    }
	    else{
	    	return(
   				<div className="row">
	   				<button type="button" className = 'mx-auto btn btn-info my-3' 
	   					onClick = {this.props.updateTeacherCepstrum}>
	   					Show teacher's audio
	   				</button>  
   				</div> 	
	    	)
	    }
  	}

   	renderAttempt(){
   		return(
   			<div>
				<Card className="px-2 py-2 my-2 shadow border border-info border-1 my-2">
				    <Card.Text>
				      	<p className="text-left">
				      		Teacher's recording ({this.props.currentCard.pinyin}):
				      	</p>
				      	<div className = "row">
				      		<div className = "col-6-sm col-12">
				   				<audio src={this.props.currentCard.file_path}
				   						controls={true} display
				   				/>							</div>
				      		<div className = "col-6-sm col-12">
				   				<PlotTemplate dataToPlot={this.props.teacherCepstrum} 
				   				    style = {{display: this.props.teacherCepstrum ? 'block' : 'none' }}
								/>
							</div>
			 			</div>
				    </Card.Text>
				</Card>									
   			</div>
   			)
   	}
}

class SelfAssessment extends Component {
	render(){
		return(
			<div class="btn-group py-2" role="group">
			  <button type="button" class="btn btn-outline-success"
			  	onClick={this.props.correctClick}>
			  	Correct
			  </button>
			  <button type="button" class="btn btn-white" disabled></button>
			  <button type="button" class="btn btn-outline-danger"
			  	onClick={this.props.wrongClick}>
			  	Wrong
			  </button>
			</div>			
		)
	}
}

class CardCounter extends Component {
	render(){
		return(
			<div className = "row justify-content-center py-4">
			  	<div className = "col-md-3 col-4">
				  	<Card border="white">
					    <Card.Subtitle className="mb-2 text-muted">
					    	{this.props.lenUnknown} unknown cards
					    </Card.Subtitle>
					</Card>
			  	</div>
			  	<div className = "col-md-3 col-4">
				  	<Card border = "white">
					    <Card.Subtitle className="mb-2 text-muted">
					    	{this.props.lenKnown} known cards
					    </Card.Subtitle>
					</Card>
			  	</div>
			</div>				

			)
	}
}


class HSK1 extends Component {
	constructor(props) {
    super(props);

    let cardDeck = new CardDeck(CardArray)

    this.state = {
    	cardDeck: cardDeck,
    	currentCard: cardDeck.getUnknownCard(),
    	lenKnownCards: 0,
    	lenUnknownCards: cardDeck.getUnknownLength(),
    	madeAttempt: false,
    	teacherCepstrum: null,
    	nWordsTried: 0,
    }
    this.getDataToPlot = this.getDataToPlot.bind(this);
    this.recordMadeAttempt = this.recordMadeAttempt.bind(this);
    this.updateTeacherCepstrum = this.updateTeacherCepstrum.bind(this);    
    this.correctClick = this.correctClick.bind(this);
    this.wrongClick = this.wrongClick.bind(this);
  }		

	recordMadeAttempt(){
		this.setState({
			madeAttempt: true,
		})

	    ReactGA.event({
	      category: 'record',
	      action: 'Click Record, tried ' + this.state.nWordsTried
	                              + ' words, playing HSK1 tone pairs',
	    });		
	}

	async updateTeacherCepstrum() {

	    ReactGA.event({
	      category: 'Clue',
	      action: 'Click show teacher, tried ' + this.state.nWordsTried
	                              + ' words, playing HSK1 tone pairs',
	    });	  	

		// get audio samples from link and update teacher cepstrum
		let url = this.state.currentCard.file_path
		const audioContext = new (window.AudioContext || window.webkitAudioContext)({sampleRate:16000});

		var that = this;
		fetch(url)
			.then(response => response.arrayBuffer())
			.then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
			.then(audioBuffer => audioBuffer.getChannelData(0))
			.then(rawData => that.getDataToPlot(rawData))
			.then(cepstrum => {
				this.setState({ teacherCepstrum: cepstrum})
				})
	}

  correctClick(){
  	this.state.cardDeck.changeCardToKnown(this.state.currentCard)
  	this.setState({
  		currentCard: this.state.cardDeck.getUnknownCard(),
  		lenKnownCards: this.state.cardDeck.getKnownLength(),
  		lenUnknownCards: this.state.cardDeck.getUnknownLength(),
  		madeAttempt: false,
  		teacherCepstrum: null,
  		nWordsTried: this.state.nWordsTried + 1,
  	})

	    ReactGA.event({
	      category: 'Next',
	      action: 'Click correct, tried ' + this.state.nWordsTried
	                              + ' words, playing HSK1 tone pairs',
	    });	  	
  }

  wrongClick(){
  	this.setState({
  		currentCard: this.state.cardDeck.getUnknownCard(),
  		lenKnownCards: this.state.cardDeck.getKnownLength(),
  		lenUnknownCards: this.state.cardDeck.getUnknownLength(),
  		madeAttempt: false,
  		teacherCepstrum: null,
  		nWordsTried: this.state.nWordsTried + 1,
  	})

	    ReactGA.event({
	      category: 'Next',
	      action: 'Click wrong, tried ' + this.state.nWordsTried
	                              + ' words, playing HSK1 tone pairs',
	    });	  	  	
  }

  async getDataToPlot(samples){
    // convert samples from typed array to regular array
    samples = Array.prototype.slice.call(samples);

    // getting sliding windows
    const w_size = 400;
    const s_size = 160;
    const n_fft = 512;
    const N_WINDOWS_FINAL = 160;

    const padded_length = N_WINDOWS_FINAL * s_size + w_size;
    samples = tf.tensor1d(samples)
    samples = samples.pad([[0, padded_length - samples.shape[0]]])

    let windows = tf.signal.frame(samples, w_size, s_size, true, 0)
    windows = windows.transpose()

    const hammingWind = tf.signal.hammingWindow(w_size).reshape([-1, 1])
    windows = tf.mul(windows, hammingWind)

    // apply fft
    let real = windows;
    // pad real with 0s to length 512
    real = real.pad([[0, n_fft - w_size], [0, 0]]).transpose()

    let complex = tf.cast(real, 'complex64')
    let fft_result = complex.fft();    

    // take absolute value
    let abs_result = fft_result.abs();
    abs_result = abs_result.transpose();
    abs_result = abs_result.slice([0, 0], [256])

    // take log
    let log_result = abs_result.log();
    // account for logs of zeros
    let mask_array = log_result.isInf();
    log_result = tf.where(mask_array, tf.zerosLike(log_result), log_result);

    //apply ifft
    var imag_ifft = tf.zerosLike(log_result.transpose())
    var x_to_ifft = tf.complex(log_result.transpose(), imag_ifft)
    let ifft_result = x_to_ifft.irfft().transpose()

    let cepstrum = ifft_result.slice([25, 0], [175, N_WINDOWS_FINAL])
    cepstrum = cepstrum.transpose()

    var cepstrumToPlot = cepstrum.arraySync();

    return cepstrumToPlot;
  }

  isFacebook = isFacebookApp() 

	render(){
	    // for facebook in-app browser
	    if (this.isFacebook === true){

	      ReactGA.event({
	        category: 'browser',
	        action: 'Rendered in Facebook browser',
	      });

	      return(
	      		<div className='App container'>
		          <FacebookBrowserPrompt/>
	      		</div>
	      )
	    }		

		else if (this.state.lenUnknownCards === 0){
		      ReactGA.event({
		        category: 'game finished',
		        action: 'hsk1 tone pairs finished',
		      });			
			return(
				<div>
					<p className = "pt-5 pb-3"><strong>Congratulations! You have finished the game!</strong></p>
					<p className = "pt-5 pb-3">We plan to release a fully-fledged app soon. Subscribe for more updates below.</p>         
					<div class="embed-responsive embed-responsive-1by1">
					  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe1nYH2czLDkg67EwIjsit5rO41XdRx2r-f4TV9u3fLGjpGXw/viewform?embedded=true" 
					  width="640" height="383"
					  class="embed-responsive-item"
					  title="google form signup"
					  marginheight="0" marginwidth="0">Loading…</iframe>
					</div> 
				</div>
			)
		}
		else {


		      ReactGA.event({
		        category: 'browser',
		        action: 'Rendered in non-Facebook browser',
		      });			
			return (
			        <div className='App container'>
				        <div className = "row justify-content-left"> 
				          <div className = "col my-auto">
				            <h1> <small>HSK 1 tone pairs game</small> </h1>
				          </div>
				        </div>			        	
						<div>
							<h2>
								<strong>{this.state.currentCard.characters}</strong>
							</h2>
							<div className = "container">
								<div className="row justify-content-around">
									<div className = "col-md-5 col-12">
										<You getDataToPlot={this.getDataToPlot}
												madeAttempt={this.state.madeAttempt}
												recordMadeAttemptFunc = {this.recordMadeAttempt}
										/>
									</div>
								</div>
								<div className="row justify-content-around">
									<div className = "col-md-7 col-12">
										<Teacher getDataToPlot={this.getDataToPlot}
													updateTeacherCepstrum={this.updateTeacherCepstrum}
													teacherCepstrum = {this.state.teacherCepstrum}
													currentCard = {this.state.currentCard}
													madeAttempt={this.state.madeAttempt}/>
									</div>
								</div>
							</div>
							<SelfAssessment correctClick = {this.correctClick}
											wrongClick = {this.wrongClick}/>

							<CardCounter lenUnknown = {this.state.lenUnknownCards}
											lenKnown = {this.state.lenKnownCards}/>										
						</div>
			        </div>
			        )
				}
	}
}

export default HSK1;
