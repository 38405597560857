import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Plotly from 'plotly.js-cartesian-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

import * as tf from '@tensorflow/tfjs';
import Recorder from './Recorder';
import FacebookBrowserPrompt from './FacebookBrowserPrompt';
import '../index-style.css';
import cepstra from '../cepstra.json'

import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
// import Modal from 'react-bootstrap/Modal';

import queryString from 'query-string';

import ReactGA from 'react-ga';



function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}  


const Plot = createPlotlyComponent(Plotly);
const MODEL_URL = '/model/model.json';
const toneToOrdinal=[null, 'First tone', 'Second tone', 'Third tone', 'Fourth tone']


const cepstraJSON = JSON.parse(cepstra);
//const numberToCharacter = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']

const VIJ = { // very important json
  'nums': {
    'headerText': 'Numbers game',
    'translation': ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    'indexToPinyinAccent': ['líng', 'yī', 'èr', 'sān', 'sì', 'wǔ', 'liù', 'qī', 'bā', 'jiǔ', 'shí'],
    'indexToTone': [2, 1, 4, 1, 4, 3, 4, 1, 1, 3, 2],
    'indexToCepstrumKey': ['2ling', '1yi', '4er', '1san', '4si', '3wu', '4liu', '1qi', '1ba', '3jiu', '2shi'],
    'webPre': Array(11).fill('https://downloads.bbc.co.uk/schools/primarylanguages/audio/mandarin/numbers1_10/md_'),
    'webMid':  ['ling', 'yi', 'er', 'san', 'si', 'wu', 'liu', 'qi',
                    'ba', 'jiu', 'shi'],
    'webPost': Array(11).fill('_vo.mp3'),
    'changeButtonText': 'Tone Alert game',
  },
  'alert':{
    'headerText': 'Tone Alert game',
    'translation': [null, 'drink', 'and', 'buy', 'sell', 'fish', 'rain', 'ten', 'be', 'kiss', 'ask'],
    'indexToPinyinAccent': [null, 'hē', 'hé', 'mǎi', 'mài', 'yú', 'yǔ', 'shí', 'shì', 'wěn', 'wèn', 'mā', 'mǎ'],
    'indexToTone': [null, 1, 2, 3, 4, 2, 3, 2, 4, 3, 4],
    'indexToCepstrumKey': ['', '1he', '2he', '3mai', '4mai', '2yu', '3yu', '2shi_alert', '4shi',
                  '3wen', '4wen'],
    'webPre': ["",
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-f9cd093c.ogg", // he1
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-f711ecca.ogg",

                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-afcc0713.ogg",
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-c7291b2e.ogg",

                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-667b5263.ogg",
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-13ca4a36.ogg",

                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-44fa9ee9.ogg",
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-7fc20de5.ogg",

                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-7d9a2b47.ogg",
                "http://packs.shtooka.net/cmn-caen-tan/ogg/cmn-561ca00f.ogg",               
              ],
    'webMid': Array(11).fill(''),
    'webPost': Array(11).fill(''),
    'changeButtonText': "Numbers game",
  }
}

class Game extends Component {
	constructor(props) {
    super(props);
    this.state = {
      progress: 1,  
      attemptRec: null,
      attemptURL: null,
      attemptSamples: null,
      correct: null,
      toneHeard: null,
      dataToPlot:null,
      game: 'alert',
      showTranslation: true,
      nNextPresses: 0
    }
    this.submitAudio = this.submitAudio.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.changeGame = this.changeGame.bind(this);
    this.getDataToPlotAndPred = this.getDataToPlotAndPred.bind(this);
  }

  setWordIndexIfValid(index){
      	if (index && index !== -1){
      		this.setState({
      			progress: index,
      		})
      	}
    }

  async componentDidMount() {
      this.model = await tf.loadLayersModel(MODEL_URL)

	  const { location: { search } } = this.props;
	  const qMarkValues = queryString.parse(search); 


	  ////////////// LOOKING AT GAMEREQUESTED /////////////
	  let gameRequested = null;
      if (qMarkValues.gameType === 'tonealert') {
      	gameRequested = 'alert';
      	this.setState({
      		game: 'alert'
      	})
      }
      if (qMarkValues.gameType === 'numbers') {
		gameRequested = 'nums';      	
      	this.setState({
      		game: 'nums'
      	})
      }

	  ////////////// LOOKING AT WORD /////////////    
      // if given a word
      if (qMarkValues.word){
      	// if game is specified
      	if (gameRequested) {
	      	let wordIndex = VIJ[gameRequested].indexToCepstrumKey.indexOf(qMarkValues.word)
	      	console.log(wordIndex)
	      	this.setWordIndexIfValid(wordIndex)
      	}

	  	else { // try for both games, defaulting to tone alert
	  	  const wordIndexNum = VIJ['nums'].indexToCepstrumKey.indexOf(qMarkValues.word)
	      this.setWordIndexIfValid(wordIndexNum)
	      if (wordIndexNum !== -1) {
	      	this.setState({
	      		game: 'nums'
	      	})
	      }

		  const wordIndexAlert = VIJ['alert'].indexToCepstrumKey.indexOf(qMarkValues.word)
		  this.setWordIndexIfValid(wordIndexAlert)      	
	      if (wordIndexAlert !== -1) {
	      	this.setState({
	      		game: 'alert'
	      	})
	      }		  
	    }  
	  } 

	  ////////////// LOOKING AT MEANING TOGGLE /////////////
	  if (qMarkValues.meaning){
	  	if (qMarkValues.meaning === 'on') {
	  		this.setState({
	  			showTranslation: true,
	  		})
	  	}
	  	if (qMarkValues.meaning === 'off') {
	  		this.setState({
	  			showTranslation: false,
	  		})
	  	}	  	
	  }
    }


  async submitAudio(audioSamples, audio){
    ReactGA.event({
      category: 'record',
      action: 'Click Record, state ' + this.state.progress
                              + ', playing ' + this.state.game,
    });


    var blobURL = URL.createObjectURL(audio)

    this.getDataToPlotAndPred(audioSamples).then(response => {
        this.setState({
          toneHeard: response[0],
          dataToPlot: response[1],
        })

        if (response[0] === VIJ[this.state.game].indexToTone[this.state.progress]) {
          this.setState({
            correct: true
          })
        }
        else{
          this.setState({correct: false});
        }
      }
      );

    this.setState({
      attemptRec: audio,
      attemptURL: blobURL,
      attemptSamples: audioSamples,
    })
  }

  clickNext(){
    this.setState({
      correct:null,
      progress: this.state.progress + 1,
      nNextPresses: this.state.nNextPresses + 1,
    })
    ReactGA.event({
      category: 'next',
      action: 'Click Next when on state ' + this.state.progress
                  + ', playing ' + this.state.game,
    });

  }

  changeGame(){
    var otherGame = (this.state.game === 'nums') ? 'alert' : 'nums'
    this.setState({
      game: otherGame,
      progress: 1,
      correct: null,
    })
    ReactGA.event({
      category: 'Switch game',
      action: 'Switch game when on state ' + this.state.progress
                      + ', playing ' + this.state.game,
    });
  }

  async getDataToPlotAndPred(samples){
    // convert samples from typed array to regular array
    samples = Array.prototype.slice.call(samples);

    // getting sliding windows
    const w_size = 400;
    const s_size = 160;
    const n_fft = 512;
    const N_WINDOWS_FINAL = 140;

    // const n_windows = Math.floor(samples.length / s_size)+1; // so actually, ceil
    // console.log('n_windows:', n_windows)
    // const padded_length = n_windows * s_size + w_size;

    let windows = tf.signal.frame(tf.tensor1d(samples), w_size, s_size, true, 0)
    windows = windows.transpose()

    const hammingWind = tf.signal.hammingWindow(w_size).reshape([-1, 1])
    windows = tf.mul(windows, hammingWind)

    // apply fft
    let real = windows;
    // pad real with 0s to length 512
    real = real.pad([[0, n_fft - w_size], [0, 0]]).transpose()

    let complex = tf.cast(real, 'complex64')
    let fft_result = complex.fft();    

    // take absolute value
    let abs_result = fft_result.abs();
    abs_result = abs_result.transpose();
    abs_result = abs_result.slice([0, 0], [256])

    // take log
    let log_result = abs_result.log();
    // account for logs of zeros
    let mask_array = log_result.isInf();
    log_result = tf.where(mask_array, tf.zerosLike(log_result), log_result);

    //apply ifft
    var imag_ifft = tf.zerosLike(log_result.transpose())
    var x_to_ifft = tf.complex(log_result.transpose(), imag_ifft)
    let ifft_result = x_to_ifft.irfft().transpose()

    let cepstrum = ifft_result.slice([35, 0], [125, N_WINDOWS_FINAL])
    cepstrum = cepstrum.transpose()

    // crop using COM
    //hack for now
    var cepstrumClipped = tf.pow(tf.clipByValue(cepstrum, 0, 100), 4);
    var cepstrumCollapsed = cepstrumClipped.sum(0);
    var totalMass = cepstrum.sum()

    var massTimesDist = tf.mul(cepstrumCollapsed, tf.range(0, 125, 1));
    var COM = tf.div(massTimesDist.sum(), totalMass).dataSync();
    COM = parseInt(COM);
    var cepstrumToPlot = cepstrum.arraySync();

    if (COM <= 30){
      cepstrum = cepstrum.slice([0, 0], [N_WINDOWS_FINAL, 60])
    }
    else if (COM + 30 >= 125) {
      cepstrum= cepstrum.slice([0, 65], [N_WINDOWS_FINAL, 60])
    }
    else if (!isNaN(COM)) {
      cepstrum = cepstrum.slice([0, COM-30], [N_WINDOWS_FINAL, 60])
    }
    else {
      cepstrum = cepstrum.slice([0, 0], [N_WINDOWS_FINAL, 60])      
    }

    // var cepstrumToPlot = cepstrum.arraySync();
    //normalise cepstrum
    cepstrum = tf.div(cepstrum, tf.norm(cepstrum));

    var cepstrumForModel = cepstrum.transpose().expandDims(2).expandDims(0);
    let predictions = this.model.predict(cepstrumForModel);

    return [predictions.argMax(1).dataSync()[0] + 1, cepstrumToPlot];
  }

  isFacebook = isFacebookApp()

  render(){
    // for facebook in-app browser
    if (this.isFacebook === true){

      ReactGA.event({
        category: 'browser',
        action: 'Rendered in Facebook browser',
      });

      return(
      		 <div className='App container'>
	          <Header game = {this.state.game} changeGame={this.changeGame} showChangeGame = {false}/>
	          <FacebookBrowserPrompt/>
      		</div>
      )
    }

    else if (this.state.progress > 10) {
      return(
        <div className = 'App container'>
			<Header game = {this.state.game} changeGame={this.changeGame} showChangeGame = {true}/>
			<p className = "pt-5 pb-3"><strong>Congratulations! You have finished the game!</strong></p>
			<p className = "pt-5 pb-3">We plan to release a fully-fledged app soon. Subscribe for more updates below.</p>         
			<div class="embed-responsive embed-responsive-1by1">
			  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe1nYH2czLDkg67EwIjsit5rO41XdRx2r-f4TV9u3fLGjpGXw/viewform?embedded=true" 
			  width="640" height="383"
			  class="embed-responsive-item"
			  title="google form signup"
			  marginheight="0" marginwidth="0">Loading…</iframe>
			</div>        
        </div>
      )
    }

    else{
      ReactGA.event({
        category: 'browser',
        action: 'Rendered in non-Facebook browser',
      });

      return (
        <div className='App container'>
          <Header game = {this.state.game} changeGame={this.changeGame} showChangeGame = {true}/>
          <div>
            <Prompt className = "" 
            	progress= {this.state.progress} game = {this.state.game}

            	showTranslation = {this.state.showTranslation}/>
            <div className = "container">
              <div className="row justify-content-around">
                <div className = "col-md-5 col-12">
                  <Teacher className = "py-3" 
                    progress = {this.state.progress} game = {this.state.game}
                  />
                </div>
                <div className = "col-md-7 col-12">
                  <Feedback className = "py-3" 
                    dataToPlot = {this.state.dataToPlot} clickNext = {this.clickNext} 
                    onSubmit= {this.submitAudio}  progress= {this.state.progress} audioFile={this.state.attemptURL} 
                    tone={this.state.toneHeard} correct={this.state.correct} 
                    game = {this.state.game}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    }

}
}

// function SignUpModal(props) {
//   if (props.nNextPresses === 4) {
//     ReactGA.event({
//       category: 'Signup modal',
//       action: 'Showed signup modal',
//     });

//     const [show, setShow] = React.useState(true);

//     const handleClose = () => setShow(false);
//     // const handleShow = () => setShow(true);  

//     return(
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//           Hi, looks like you enjoy using this website
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body className='text-justify'>
//         We plan to release a fully-fledged tone-training app soon. Subscribe for more updates below.       
//       <div class="embed-responsive embed-responsive-1by1">
//         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe1nYH2czLDkg67EwIjsit5rO41XdRx2r-f4TV9u3fLGjpGXw/viewform?embedded=true" 
//         width="640" height="383"
//         class="embed-responsive-item"
//         title="google form signup"
//         marginheight="0" marginwidth="0">Loading…</iframe>
//       </div>        
//         </Modal.Body>
//       </Modal>      
//     )
//   }
//   else{
//     return null;
//   }
// }


class Header extends Component{
  render() {
    return(
      <div className="container-fluid">
        <div className = " pt-2 row justify-content-end"> 
          <button className='btn btn-link' 
            style = {{display: this.props.showChangeGame ? 'block' : 'none' }}
            onClick={this.props.changeGame}
            > Switch to the <strong>{VIJ[this.props.game].changeButtonText}</strong>
          </button> 
        </div>
        <div className = "row justify-content-left"> 
          <div className = "col-md-4 col-sm-4 my-auto">
            <h1> <small>{VIJ[this.props.game].headerText}</small> </h1>
          </div>
        </div>
      </div>
    )
  }
}


class Prompt extends Component {
  render() {
    return (
    <div className='container py-2'>
      <div className = "row justify-content-around">
        <div className = "col-12 col-sm-8 col-md-6">
          <div className='row justify-content-around' id='numbers'>
            <h6 className='col-2'>{VIJ[this.props.game].indexToPinyinAccent[this.props.progress]}</h6>
            <h6 className='col-2'
				style = {{display: this.props.showTranslation ? 'block' : 'none' }}>
            	{VIJ[this.props.game].translation[this.props.progress]}
            </h6>        
            <h5 className='col-6'>{toneToOrdinal[VIJ[this.props.game].indexToTone[this.props.progress]]}</h5>
          </div>
          <div className="">
            <ProgressBar now={this.props.progress * 10}/>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

class Teacher extends Component {
  render(){
    return(
      <div>
        <Card className = "shadow border border-warning border-3 my-2" border = "">
          <Card.Title className="pt-1">Teacher's vocal pitch</Card.Title>
          <div className = "row">
            <div className = " col-12 my-auto py-2">
              <audio className='mx-auto px-1 row' 
                src={VIJ[this.props.game].webPre[this.props.progress] 
                  + VIJ[this.props.game].webMid[this.props.progress] 
                  + VIJ[this.props.game].webPost[this.props.progress]}
                controls={true}/>
            </div>
            <div className = "col-12 mx-auto my-auto py-2 text-center" >
              <Plot data={[
                {
                  z: cepstraJSON[VIJ[this.props.game].indexToCepstrumKey[this.props.progress]],
                  type: 'heatmap',
                  colorscale: 'YIGnBu',
                  showscale: false,
                },
              ]
            }
              layout={ {width: 200, height: 120,
                        margin: {l:0, r: 0, b: 0, t: 0},
                        xaxis: {ticks:'', showticklabels: false},
                        yaxis: {ticks: '', showticklabels: false, autorange: 'reversed'},
                        }}
              config={{staticPlot:true}}
              />
            </div>
          </div>
        </Card>   
      </div>
    )
  }
}


class Feedback extends Component {
  render() {
    if (this.props.correct != null) { 
      return this.renderAttempt();
    }
    else{
      return(
        <div>
          <Card className = "text-center my-2 shadow border border-warning border-3">
            <Card.Title className = ""> Your vocal pitch </Card.Title>
            <Card.Subtitle className="text-muted">Press & speak to see how your tone compares</Card.Subtitle>
            <Card.Body>
              <div className = "col-md-2 col-12 my-2">
                <Recorder className = ""  onSubmit={this.props.onSubmit}/>
              </div>
              <div className = "col-md-9 col-12 mx-auto my-auto py-1 text-center">
                <span></span>
              </div>              
            </Card.Body>
          </Card> 
        </div>
        )
    }
  }
  
  renderAttempt() {
    return(
      <div>
        <Card className = "shadow border border-warning border-3 my-2" border = "">
          <Card.Title className = ""> Your vocal pitch </Card.Title>
          <Card.Subtitle className="text-muted">Press & speak to see how your tone compares</Card.Subtitle>
          <Card.Body className = "">
            <div className = "row justify-content-between">
              <div className = "col-md-2 col-12 my-auto mx-auto">
                <Recorder onSubmit={this.props.onSubmit}/>
              </div>
              <div className = "col-md-9 col-12 mx-auto my-auto text-center">
                <audio src={this.props.audioFile} controls={true}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-auto text-center">
                  <Plot data={[
                    {
                      z: this.props.dataToPlot,
                      type: 'heatmap',
                      colorscale: 'YIGnBu',
                      showscale: false,
                      transpose: true,
                    },
                  ]} 

                  layout={ {width: 220, height: 120,
                            margin: {l:0, r: 0, b: 0, t: 0},
                            xaxis: {ticks:'', showticklabels: false},
                            yaxis: {ticks: '', showticklabels: false, autorange: 'reversed'},
                            }}
                  config={{staticPlot:true}}
                  />    
              </div>
            </div>
            <div className = "row">
              <div className = "col-md-7 col-12 my-auto">
                {this.props.correct ? 'Correct tone!' : 'It sounded like tone ' 
                      + this.props.tone + ', but it should be tone ' 
                      + VIJ[this.props.game].indexToTone[this.props.progress] 
                      + ', please try again'} 
              </div>
              <div className = "col-md-5 col-12">
                  <button className='btn btn-success my-3 float-right'
                   onClick={this.props.clickNext}
                    > Next word
                  </button>
              </div>
            </div>
          </Card.Body>
        </Card>   
      </div>
    )
  }
}


export default Game;