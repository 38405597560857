import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';

import '../index-style.css';



class Landing extends Component{
  render() {
  	return(
  		<div class='container'>
  			<div className='row justify-content-around'>
	  			<div className='col-md-9 col-12'>
					<div class="jumbotron bg-light">
					  <h1 class="display-4">Take the guesswork out of practicing the tones.</h1>
					  <p class="lead">We give you instant feedback on your pronunciation.</p>
					  <hr class="my-4"/>
					  <p>Try our <strong>HSK1 tone pairs</strong> game and see how our 'helping ear' can help you.</p>
					  <a class="btn btn-info btn-lg" href="/hsk1" role="button">Try it out</a>
					</div>
				</div>
				<div className='col-md-3 col-4 mx-auto my-auto'>
					<img className="img-fluid" src='/tone_pairs_mockup.PNG'
							alt=''/>
				</div>
			</div>
			<hr/>

			<div class='row justify-content-around'>
				<div class = 'col py-3'>
					Or try our other games. Please note that all games currently require Google Chrome or Opera browsers.
				</div>
			</div>
			<div class = 'row justify-content-around'>
				<div class='col-md-3 col-6'>
					<Link to={'/games/numbers'} className='mute-link'>
					  <Card >
					    <Card.Img variant="top" src="/numbers_game_icon.PNG" />
					    	<Card.Title className="mt-2">
					  		Numbers game
					  		</Card.Title>
					  		<Card.Text className="mb-2">
					  			Learn to count 1-10 with perfect tones.
					  		</Card.Text>			      
					  </Card>
					</Link>
				</div>
				<div class='col-md-3 col-6'>
					<Link to={'/games/tonealert'} className='mute-link'>
					  <Card >
					    <Card.Img variant="top" src="/tone_alert_icon.PNG" />
					    	<Card.Title className="mt-2">
					  		Tone alert game
					  		</Card.Title>
					  		<Card.Text className="mb-2">
					  			Practice 10 commonly-confused words.
					  		</Card.Text>			      
					  </Card>
					</Link>
				</div>	
			</div>



  		</div>)

  }
}

export default Landing;